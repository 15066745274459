import React from "react"

const Navigation = () => {
  return (
    <nav className="navbar navbar-expand-lg fixed-top trans-navigation">
      <div className="container">
        <a className="navbar-brand" href="/">
          <img src="/images/logo.png" alt="" className="img-fluid b-logo" />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#mainNav"
          aria-controls="mainNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon">
            <i class="fa fa-bars"></i>
          </span>
        </button>

        <div class="collapse navbar-collapse justify-content-end" id="mainNav">
          <ul className="navbar-nav ">
            <li className="nav-item">
              <a className="nav-link smoth-scroll" href="/our-work">
                Work
              </a>
            </li>
            {/* <li className="nav-item">
              <a className="nav-link smoth-scroll" href="/#service-head">
                Services
              </a>
            </li> */}
            {/* <li className="nav-item">
              <a className="nav-link smoth-scroll" href="about.html">
                About
              </a>
            </li> */}
            <li className="nav-item">
              <a className="nav-link smoth-scroll" href="/insights">
                Blog
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link smoth-scroll" href="/get-in-touch">
                <span className="nav-link__cta">Contact Us</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Navigation
