import React from "react"

const Footer = () => {
  return (
    <section id="footer" className="section-padding">
      <div className="container">
        <div className="row justify-content-around">
          <div className="col-lg-4">
            <div className="footer-widget footer-link mb-4">
              <h4>
                We develop
                <br />
                technical solutions
              </h4>
              <p>
                We provide technical development services & partner with
                businesses looking to expand their digital offering.
              </p>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-6">
            <div className="footer-widget footer-link">
              <h4>Company</h4>
              <ul>
                <li>
                  <a href="https://invently.blob.core.windows.net/website/Invently%20Ltd%20-%20%20Terms%20and%20Conditions.pdf">
                    Terms &amp; Conditions
                  </a>
                </li>
                <li>
                  <a href="/cookies">Cookie Policy</a>
                </li>
                {/* <li>
                  <a href="work.html">Work</a>
                </li>
                <li>
                  <a href="service.html">Services</a>
                </li>
                <li>
                  <a href="about.html">About</a>
                </li>
                <li>
                  <a href="/insights">Blog</a>
                </li>
                <li>
                  <a href="https://invently.blob.core.windows.net/website/Invently%20Ltd%20-%20%20Terms%20and%20Conditions.pdf">
                    Terms &amp; Condition
  </a>
                </li>*/}
              </ul>
            </div>
          </div>

          <div className="col-lg-2 col-md-4 col-6">
            <div className="footer-widget footer-link">
              <h4>Insights</h4>
              <ul>
                <li>
                  <a href="/our-work">Work</a>
                </li>
                {/* <li>
                  <a href="service.html">Services</a>
                </li>
                <li>
                  <a href="about.html">About</a>
                </li> */}
                <li>
                  <a href="/insights">Blog</a>
                </li>
              </ul>
              {/* <h4>Insights</h4>
              <ul>
                <li>
                  <a href="/insights">Blog</a>
                </li>
                <li>
                  <a href="#!">Resources</a>
                </li>
                <li>
                  <a href="#!">Labs</a>
                </li>
              </ul> */}
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-12">
            <div className="footer-widget footer-text">
              <h4>Our location</h4>
              <p className="mail">
                <span>Mail:</span> hello@invently.co.uk
              </p>
              <p>
                <span>Location:</span> Sheffield, UK
              </p>
              <ul className="list-unstyled list-inline footer-social mt-2">
                <li className="list-inline-item">
                  <a href="https://twitter.com/invently_uk">
                    <i className="fab fa-twitter"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="https://uk.linkedin.com/in/craigpickles1">
                    <i className="fab fa-linkedin"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="https://github.com/craigpickles">
                    <i className="fab fa-github"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 text-center">
            <div className="footer-copy">
              &copy; 2017 - 2022, Invently Ltd is a limited company registered
              in England and Wales. Registered number: 10929292
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Footer
