import React from "react"
import { config } from "@fortawesome/fontawesome-svg-core"
import Navigation from "../components/navigation"
import Footer from "../components/footer"

config.autoAddCss = false

const Layout = ({ location, title, children }) => {
  return (
    <div>
      <Navigation />
      <main>{children}</main>

      <Footer />
    </div>
  )
}

export default Layout
